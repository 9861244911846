import React from 'react';
import { graphql } from 'gatsby';
import moment from 'moment';
import styled from 'styled-components';

import SEO from '../components/seo';
import Layout from '../components/layout';
import Box from '../components/styled/base/Box';
import Container from '../components/styled/Container';
import Icon from '../components/styled/Icon';
import Typography from '../components/styled/base/Typography';
import A from '../components/styled/base/A';
import Flex from '../components/styled/base/Flex';

const PaginationLink = styled(A)`
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  
  `};

  transition: all 0.3s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const PageNumber = styled(Box)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 0.9em;
  color: white;
`;

const Card = styled(Flex)`
  position: relative;

  &::before {
    content: '';
    transition: all 0.3s ease-in-out;
    position: absolute;
    bottom: 10px;
    left: 16px;
    width: 0;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.primary};
  }

  &:hover {
    h2,
    svg {
      color: ${({ theme }) => theme.colors.secondary};
      transition: all 0.3s ease-in-out;
    }

    &::before {
      width: 80%;
      background-color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

const Posts = ({ data, pageContext }) => {
  const posts = data.posts.edges;

  const { currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? '' : (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();

  return (
    <Layout>
      <SEO title="Posts" />
      <Container mb={4}>
        <Box mt={[2, 3, 4]} p={2} mb={3} bg="primaryLight">
          <Typography textAlign="center" color="primary" as="h2">
            Posts Archive
          </Typography>
        </Box>
        <Flex flexWrap="wrap" mx={-3} justifyContent="center">
          {posts.map(({ node: { title, slug, createdAt } }) => (
            <Card
              as={A}
              to={`/posts/${slug}`}
              width={[1, 1 / 2, 1 / 3]}
              p={3}
              key={slug}
            >
              <Icon color="currentColor" name="chevron_right" mr={2} mt={1} />
              <Flex
                flexDirection="column"
                borderRadius="3px"
                justifyContent="space-between"
                flex="1"
              >
                <Typography
                  as="h2"
                  color="primary"
                  fontSize={3}
                  fontWeight="semibold"
                  my={0}
                >
                  {title}
                </Typography>
                <Typography fontSize={0} color="text">
                  {moment(createdAt).format('D MMMM YYYY')}
                </Typography>
              </Flex>
            </Card>
          ))}
        </Flex>
        <Box textAlign="center">
          <PaginationLink
            disabled={isFirst}
            p={2}
            color="primary"
            to={`/posts/`}
            rel="first"
          >
            <Icon size="1.2em" name="first" />{' '}
            <Typography display={['none', 'inline']}>Eerste</Typography>
          </PaginationLink>
          <PaginationLink
            disabled={isFirst}
            p={3}
            color="primary"
            to={`/posts/${prevPage}`}
            rel="prev"
          >
            <Icon size="1.2em" name="previous" />{' '}
            <Typography display={['none', 'inline']}>Vorige</Typography>
          </PaginationLink>

          <PageNumber bg="primary" px={2} py={1}>
            {currentPage} / {numPages}
          </PageNumber>

          <PaginationLink
            disabled={isLast}
            px={3}
            py={2}
            color="primary"
            to={`/posts/${nextPage}`}
            rel="next"
          >
            <Typography display={['none', 'inline']}>Volgende</Typography>{' '}
            <Icon size="1.2em" name="next" />
          </PaginationLink>
          <PaginationLink
            disabled={isLast}
            p={2}
            color="primary"
            to={`/posts/${numPages}`}
            rel="last"
          >
            <Typography display={['none', 'inline']}>Laatste</Typography>{' '}
            <Icon size="1.2em" name="last" />
          </PaginationLink>
        </Box>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query postQuery($skip: Int!, $limit: Int!) {
    posts: allContentfulPost(
      sort: { fields: createdAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`;

export default Posts;
